import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';

// servicios
import { InactividadService } from './services/inactividad.service';
import { UsuarioService } from './services/datos/usuario.service';

// librerias
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, PageLoaderComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentUrl!: string;
  constructor(
    public usuarioSrv: UsuarioService,
    private inactivitySrv: InactividadService,
    public _router: Router
  ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        /* empty */
      }
      window.scrollTo(0, 0);
    });


    this.inactivitySrv.onInactivity().subscribe((inactive) => {
      if (inactive) {
        // Se ha detectado inactividad
        console.log('Usuario inactivo. Cerrando sesión...', this.usuarioSrv.userdata.id);
        if (this.usuarioSrv.userdata.id) {
          // Si existe usuario logueado, mostrar aviso
          Swal.fire({
            title: "Tu sesión se ha cerrado por inactividad",
            html: `<b>${this.usuarioSrv.userdata.username}</b> Por seguridad, tu sesión se ha cerrado. <br>Vuelve a identificarte.`,
            icon: "info"
          });
        }

      }
    });
  }
}
