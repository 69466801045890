import { ProgressAnimationType } from "ngx-toastr";

export const environment = {
  production: true,
  entorno: 'ENTORNO TEST',

  timeOut: 600000,   // Milisegundos de inactividad que provocan la DESCONEXIÓN automática (10min = 600000 ms)
  timeRefreshPedidos: 5000,  // Refesco automático de los datos de pedidos pendientes. 5000 milisegundos = 5 segundos
  timeRefreshEntradas: 5000,  // Refesco automático de los datos de entradas pendientes. 5000 milisegundos = 5 segundos

  apiErrorAuth: 'El sistema ha devuelto un error. <br><i>Asegurate de estar autorizado para hacer esta operación</i>',

  // API
  apiURL: 'https://api.test.innovacen.com/api',

  // Directorios
  fotoDir: 'https://api.test.innovacen.com/data/fotos',
  pdfDir: 'https://api.test.innovacen.com/data/pdfs',
  pantillasDir: 'https://api.test.innovacen.com/data/plantillas',

  // ngx-toastr
  toastrConfig: {
    timeOut: 3000,
    progressBar: false,
    progressAnimation: 'decreasing' as ProgressAnimationType,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    closeButton: false,
    enableHtml: true,
  },

};
