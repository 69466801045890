<section class="content">
    <div class="container-fluid">
        @for (breadscrum of breadscrums; track breadscrum) {
        <div class="block-header">

            <!-- breadcrumb -->
            <app-breadcrumb [title]="breadscrum.title"
                            [items]="breadscrum.items"
                            [active_item]="breadscrum.active">
            </app-breadcrumb>
        </div>
        }


        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="materialTableHeader">
                        <div class="left">
                            <ul class="header-buttons-left ms-0">
                                <li class="tbl-title">
                                    <h2>Pedidos</h2>
                                </li>

                                <li class="tbl-search-box">
                                    <label for="search-input"><i class="material-icons search-icon">search</i></label>
                                    <input (keyup)="applyFilter($event)"
                                           [(ngModel)]="dataSource.filter"
                                           [disabled]="loading"
                                           matInput
                                           placeholder="Buscar"
                                           type="search"
                                           #filter
                                           class="browser-default search-field"
                                           aria-label="Search box">
                                </li>

                            </ul>
                        </div>
                        <div class="
                                              right">
                            <ul class="tbl-export-btn">

                                <li class="tbl-header-btn">
                                    <div class="m-l-10"
                                         matTooltip="Recargar datos">
                                        <button mat-mini-fab
                                                (click)="getListaPedidos()"
                                                [disabled]="loading"
                                                color="primary">
                                            <mat-icon class="col-white">refresh</mat-icon>
                                        </button>
                                    </div>
                                </li>

                                <li>
                                    <div class="export-button m-l-10"
                                         matTooltip="Exportar a XLSX">
                                        <img src="assets/images/icons/xlsx.png"
                                             alt="Exportar a XLSX"
                                             (click)="exportExcel()" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>






                    <div class="body overflow-auto">
                        <div class="responsive_table">

                            <table mat-table
                                   [dataSource]="dataSource"
                                   matSort
                                   class="mat-elevation-z8 table table-hover">

                                <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->



                                <!-- id Column -->
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Id </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end">
                                        <div [innerHTML]="highlightSearch(rec.id, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef> <b>Total</b> </td>
                                </ng-container>

                                <!-- pedido_cli Column -->
                                <ng-container matColumnDef="pedido_cli">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Pedido </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.pedido_cli, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>

                                <!-- fecha_pedidoExterno Column -->
                                <ng-container matColumnDef="fecha_pedidoExterno">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Fecha </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.fecha_pedidoExterno, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>

                                <!-- camp_cod Column -->
                                <ng-container matColumnDef="camp_cod">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Campaña </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.camp_cod, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>


                                <!-- tot_articulos Column -->
                                <ng-container matColumnDef="tot_articulos">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="text-end"> Artículos </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end"> {{rec.tot_articulos | number: '0.0-0':'es' }} </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef
                                        class="text-end"> <b>{{getTotalArticulos() | number: '0.0-0':'es' }}</b> </td>
                                </ng-container>

                                <!-- tot_referencias Column -->
                                <ng-container matColumnDef="tot_referencias">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="text-end"> Referencias </th>
                                    <td mat-cell
                                        *matCellDef="let rec"
                                        class="text-end"> {{rec.tot_referencias | number: '0.0-0':'es' }} </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef
                                        class="text-end"> <b>{{getTotalReferencias() | number: '0.0-0':'es' }}</b> </td>
                                </ng-container>


                                <!-- nombre Column -->
                                <ng-container matColumnDef="nombre">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Nombre </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.nombre, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>

                                <!-- poblacion Column -->
                                <ng-container matColumnDef="poblacion">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Destino </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.cp+' '+rec.poblacion, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>

                                <!-- pais_iso Column -->
                                <ng-container matColumnDef="pais_iso">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> País </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.pais_iso, dataSource.filter)"></div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>

                                <!-- cod_servicio Column -->
                                <ng-container matColumnDef="cod_servicio">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Serv. </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div [innerHTML]="highlightSearch(rec.cod_servicio, dataSource.filter)">
                                        </div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>


                                <!-- estado Column -->
                                <ng-container matColumnDef="estado">
                                    <th mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header> Estado </th>
                                    <td mat-cell
                                        *matCellDef="let rec">
                                        <div class="estado-container">
                                            <div [innerHTML]="rec.estado | pedidoEstado"></div>
                                            <span *ngIf="rec.aviso_cliente === '1'">
                                                <app-feather-icons [icon]="'settings'"
                                                                   [class]="'tbl-fav-info'"
                                                                   matTooltip="Acción especial pendiente"></app-feather-icons>
                                            </span>
                                        </div>
                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>


                                <!-- actions -->
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell
                                        *matHeaderCellDef></th>
                                    <td mat-cell
                                        *matCellDef="let row">

                                        <button mat-icon-button
                                                *ngIf="(row.estado === '3' || row.estado === '5' )"
                                                (click)="$event.stopPropagation()"
                                                (click)="descargaAlbaranClick(row.id!)"
                                                [disabled]="loading"
                                                class="tbl-action-btn"
                                                matTooltip="Abrir albarán">
                                            <app-feather-icons [icon]="'file-text'"
                                                               [class]="'tbl-fav-edit'"></app-feather-icons>
                                        </button>

                                        <button mat-icon-button
                                                *ngIf="(row.estado === '3' || row.estado === '5' )"
                                                (click)="$event.stopPropagation()"
                                                (click)="mostrarTraking(row.id!)"
                                                [disabled]="loading"
                                                class="tbl-action-btn"
                                                matTooltip="Seguimiento del envío">
                                            <app-feather-icons [icon]="'map-pin'"
                                                               class="text-success"
                                                               [class]="'tbl-fav-track'"></app-feather-icons>
                                        </button>

                                    </td>
                                    <td mat-footer-cell
                                        *matFooterCellDef></td>
                                </ng-container>






                                <!-- Mostrar las columnas Cabecera/conenido/footer -->

                                <!-- Abrir detalle del pedido -->

                                <tr mat-header-row
                                    *matHeaderRowDef="displayedColumns"></tr>

                                <tr mat-row
                                    *matRowDef="let row; columns: displayedColumns;"
                                    (click)="openDialogPedidoDetall(row)"
                                    [style.cursor]="'pointer'"
                                    matRipple></tr>

                                <tr mat-footer-row
                                    *matFooterRowDef="displayedColumns"></tr>



                                <!-- Sin datos -->
                                <div *matNoDataRow
                                     class="no-results">
                                    <span *ngIf="!loading">
                                        Sin datos...
                                    </span>
                                </div>

                            </table>



                            <!-- Loading spinner -->
                            <div class="tbl-spinner-container"
                                 *ngIf="loading">
                                <mat-progress-spinner color="primary"
                                                      [diameter]="40"
                                                      mode="indeterminate"></mat-progress-spinner>
                            </div>

                            <!-- Paginator -->
                            <div class="mt-3"></div>
                            <mat-paginator *ngIf="!loading"
                                           #paginator
                                           [length]="dataSource.filteredData.length"
                                           [pageIndex]="0"
                                           [pageSize]="10"
                                           [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>