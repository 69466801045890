/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Const from '../../../shared/constants';

// componentes
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogContent, MatDialogClose, MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators, NgForm, AbstractControl, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ArticuloDialogComponent } from 'app/articulos/dialogs/articulo-dialog/articulo-dialog.component';
import { FeatherIconsComponent } from "../../../shared/components/feather-icons/feather-icons.component";

// módulos
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MaterialModule } from 'app/auth/material/material.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

//interfaces
import { IApiEntrada, IApiEntradaDetall } from './../../IApiEntrada';
import { IListaOpciones } from 'app/interfaces/lista-opciones';
import { IArticulo } from 'app/interfaces/articulo';

//servicios
import { EntradasService } from 'app/services/datos/entradas.service';
import { DatosAuxService } from 'app/services/datos/datosAux.service';
import { NotificacionesService } from 'app/services/notificaciones.service';
import { ArticulosService } from 'app/services/datos/articulos.service';
import { UsuarioService } from 'app/services/datos/usuario.service';
import { ModalPdfService } from 'app//services/modal-pdf.service';

//librerías
import CustomVal from 'app/providers/CustomValidators';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
import { Subscription, delay } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { AlmacenesService } from 'app/services/datos/almacenes.service';




export interface IDialogData {
  id: number;
  action: string;
  entrada: IApiEntrada;   // Entrada vitaminada, en formato API
}



@Component({
  selector: 'app-entrada-dialog',
  standalone: true,
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, provideNgxMask()],
  imports: [CommonModule, MaterialModule, MatAutocompleteModule, MatIconModule, MatDialogContent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule,
    MatRadioModule, MatDatepickerModule, MatSelectModule, MatOptionModule, MatDialogClose, NgxMaskDirective, NgxMatSelectSearchModule,
    NgSelectModule, NgOptionHighlightModule, FeatherIconsComponent],
  templateUrl: './entrada-dialog.component.html',
  styleUrl: './entrada-dialog.component.scss'
})

export class EntradaDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @ViewChild('formularioCabecera') formularioCabecera: NgForm | undefined;
  @ViewChild('formularioDetalle') formularioDetalle: NgForm[] | undefined;


  private action: string;
  private id_cliente: number | null = null

  dialogTitle: string;
  txtSubmit: string = 'Modificar';

  public pdfSubs: Subscription | undefined;
  public fotoDir: string = environment.fotoDir;
  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';

  public entrada: IApiEntrada = {};
  public detallList: IApiEntradaDetall[] = [];
  public arrayDetalleForm: FormGroup[] = []; // Array de formularios de detalle  

  public articulosList: IArticulo[] = [];

  public cabeceraForm: FormGroup = new FormGroup({});
  public detallForm: FormGroup = new FormGroup({});

  public loading: boolean = false;
  public enviado: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<ArticuloDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,

    private fb: FormBuilder,
    private entradaSrv: EntradasService,
    public userSrv: UsuarioService,
    public datosAuxSrv: DatosAuxService,
    private notificSrv: NotificacionesService,
    public dialog: MatDialog,
    private articulosSrv: ArticulosService,
    private modalPdfSrv: ModalPdfService,
    private almacenesSrv: AlmacenesService

  ) {
    super();

    // Set Valores por defecto

    this.id_cliente = this.userSrv.userdata.cliente!.id

    this.action = data.action;
    if (this.action === 'edit') {
      this.dialogTitle = data.entrada.albaran!;
      this.txtSubmit = 'Modificar';

      // validar que aún exista esta entrada y que no está ya validada
      this.entrada = data.entrada;
      this.getEntrada()

    } else {
      this.dialogTitle = 'Nueva entrada';
      this.txtSubmit = 'Crear';
      this.entrada = {
        id_cliente: this.id_cliente.toString(),
        id_almacen: this.userSrv.userdata.cliente?.alm_por_defecto?.toString(),
        pales: '1'
      }
      this.addLineaDetall()   // Añadir una línea en blanco
    }

  }


  ngOnInit(): void {

    // Nos suscribimos al albaranPdf, Se emite desde el modal-pdf.component
    // Si cambia, actualizar el valor en el formulario

    this.pdfSubs = this.modalPdfSrv.nuevoPdf
      .pipe(delay(100))
      .subscribe(resp => {
        this.entrada.pdf_albaran = resp.guardado_como
        this.cabeceraForm.patchValue({
          pdf_albaran: resp.guardado_como
        })

        console.log('__pdfSubs, next recibido en la subscripción', resp)

      });


    // Definir formularios

    this.cabeceraForm = this.fb.group({
      id_cliente: [''],
      id_almacen: [''],
      albaran: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
      pdf_albaran: [''],
      devolucion: [false],
      contenedor: ['0'],
      pales: [''],
      proveedor: ['', Validators.required],
    });

    // Iterar sobre los detalles y crear un formulario para cada detall
    const entradaDetall = this.entrada.detall || [];

    entradaDetall.forEach((detalle, index) => {
      const detalleFormGroup = this.fb.group({
        id_articulo: new FormControl(detalle.id_articulo, Validators.required),
        artFoto: new FormControl(detalle.artFoto, Validators.required),
        cantidad: new FormControl(detalle.cantidad, [Validators.required, CustomVal.esEntero]),
        lote: new FormControl(detalle.lote, []),
        artSwLote: new FormControl(detalle.artSwLote, []),
        fecha_caducidad: new FormControl(detalle.fecha_caducidad, []),
      });

      this.arrayDetalleForm.push(detalleFormGroup);
      this.controlObligaLote(index);  // required lote y fecha?
    });

    this.getListaArticulos();
    this.cargaDatosEntrada();
    this.getUbiPlaya();

  }


  // Carga la entrada
  getEntrada() {
    this.loading = true
    this.entradaSrv.getEntrada(parseInt(this.entrada.id!))
      .subscribe(resp => {
        this.loading = false

        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          this.dialogRef.close();
          return
        }

        if (this.entrada.swValidado === '2') {
          this.notificSrv.aviso('error', `Esta entrada ya ha sido validada`);
          this.dialogRef.close();
          return
        }

        this.entrada = resp.data;
      })
  }

  // Buscar ubiPlaya
  getUbiPlaya() {
    this.loading = true
    this.almacenesSrv.getAlmacen(parseInt(this.entrada.id_almacen!))
      .subscribe(resp => {
        this.loading = false

        console.log('__getUbiPlaya()', resp)

        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }

        this.entrada.ubiPlaya = resp.data.ubiPlaya;
      })
  }



  // Carga los datos del pedido en el formulario
  cargaDatosEntrada() {
    console.log('__cargaDatosEntrada()', this.entrada)
    this.cabeceraForm.patchValue({
      id_cliente: this.entrada.id_cliente,
      id_almacen: this.entrada.id_almacen,
      albaran: this.entrada.albaran,
      pdf_albaran: this.entrada.pdf_albaran,
      devolucion: this.entrada.devolucion === '1', // Convertir a booleano
      pales: this.entrada.pales,
      proveedor: this.entrada.proveedor
    })
  }


  getListaArticulos() {
    const opciones: IListaOpciones = {
      id_cliente: this.id_cliente,
      swDefectuoso: Const.NO_DEFECTUOSO
    }
    this.loading = true
    this.articulosSrv.getListaFiltrada(opciones)
      .subscribe(resp => {
        this.loading = false
        console.log('__getListaArticulos()', resp)
        if (resp.error) {
          this.notificSrv.aviso('error', resp.mensaje);
          return
        }
        this.articulosList = resp.data;
      })
  }




  enviar() {
    this.enviado = true;

    // Marcar todos los controles de todos los formularios de detalle como touched
    this.arrayDetalleForm.forEach(formularioDetalle => {
      Object.keys(formularioDetalle.controls).forEach(controlName => {
        formularioDetalle.controls[controlName].markAsTouched();
      });
    });

    // Validar cabecera  
    if (this.cabeceraForm.invalid) {
      console.log("ERRORES en form cabecera:", this.cabeceraForm);
      return;
    }


    // Validar detall  
    if (!this.arrayDetalleForm.length) {
      this.notificSrv.aviso('warning', `Es necesario añadir un artículo como mínimo `);
      return;
    }

    for (let i = 0; i < this.arrayDetalleForm!.length; i++) {
      if (!this.arrayDetalleForm![i].valid) {
        console.log(`ERRORES en form detall: ${i}`, this.arrayDetalleForm![i]);
        this.notificSrv.aviso('error', `Errores en el artículo ${i + 1} `);
        return;
      }
    }


    // Ambos formularios son válidos  

    // transform del campo devoluvión a un string
    const cabeceraData = this.cabeceraForm.value;
    cabeceraData.devolucion = cabeceraData.devolucion ? '1' : '0';

    //Enviar datos de la cabecera y detalles al Back
    // const cabeceraData = this.cabeceraForm.value;
    const detalleData = this.arrayDetalleForm.map(detalleForm => detalleForm.value);
    if (this.entrada.id) cabeceraData.id = this.entrada.id;

    // añadir campos que faltan a cada línea de detalleData[]
    detalleData.forEach(detalle => {
      detalle.pale = '1';
      detalle.swDefectuoso = Const.NO_DEFECTUOSO;
    });

    const dataToSend = {
      cabecera: cabeceraData,
      detalle: detalleData,
      action: this.action,
      id_cliente: this.id_cliente,
      pales: '1',
      swValidado: '1',
      username: this.userSrv.userdata.username,
    };

    dataToSend.detalle.forEach(detalle => {
      // No eviar las fotos ni artSwLote
      delete detalle.artFoto;
      delete detalle.artSwLote;
    });


    //Guardar entrada     
    const datos: any = {
      ...cabeceraData,
      id_cliente: this.id_cliente,
      origen: '1',
      pales: '1',
      swValidado: '1',
      ubiPlaya: this.entrada.ubiPlaya,
      username: this.userSrv.userdata.username,
      detall: dataToSend.detalle,
      action: this.action
    }


    console.log("__enviar() ", datos);

    this.loading = true;
    this.entradaSrv.new(datos)
      .subscribe(resp => {
        this.loading = false;

        console.log('__entradaSrv.new(datos) ', resp)

        if (resp.error) {
          console.log(resp.mensaje);
          for (const controlName in resp.mensaje) {
            this.cabeceraForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          if (this.action === 'new') this.notificSrv.aviso('success', `Entrada ${this.cabeceraForm.get('albaran')?.value} creada correctamente `);
          if (this.action === 'edit') this.notificSrv.aviso('success', `Entrada ${this.cabeceraForm.get('albaran')?.value} modificada correctamente.`);
          // this.creaTransaccion(resp.data.id, 1)   // NO se Crea transacción positiva

          // cerrar dialogo y actalizar lista
          this.dialogRef.close();
          return


        }
      })


  }



  cancelarClick() {
    this.dialogRef.close();
    return
  }

  deleteLineaDetall(index: number) {
    console.log('__deleteLineaDetall()', this.arrayDetalleForm[index])

    // si no hay cantidad, no pedir confirmación
    const cantidad = this.arrayDetalleForm[index].value.cantidad;
    if (cantidad === '0' || cantidad === '') {
      this.arrayDetalleForm.splice(index, 1);
      return
    }

    Swal.fire({
      title: 'Eliminar referencia',
      icon: 'warning',
      html: `¿Quieres eliminar esta referencia de la entrada?`,
      showCancelButton: true,
      cancelButtonText: 'Volver',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.arrayDetalleForm.splice(index, 1);
      }
    })
  }

  addLineaDetall() {
    console.log('__addLineaDetall() arrayDetalleForm: ', this.arrayDetalleForm)
    const nuevoDetalleForm = this.fb.group({
      id_articulo: ['', Validators.required],
      cantidad: ['', [Validators.required, Validators.min(1), CustomVal.esEntero]],
      artFoto: ['_noFoto.png'],
      artSwLote: ['0'],
      lote: [''],
      fecha_caducidad: ['']
    });

    this.arrayDetalleForm.push(nuevoDetalleForm);
  }


  //// AUX    /// 

  get f() {
    return this.cabeceraForm.controls;
  }


  fd(i: number, controlName: string): AbstractControl | null {
    const detalleForm = this.arrayDetalleForm[i];
    return detalleForm.get(controlName);
  }

  // Crear nuevo artículo
  openDialogArticuloNew() {

    const dialogRef = this.dialog.open(ArticuloDialogComponent, {
      data: {
        action: 'new',
      }
    });
    // Subscribirse al resultado
    this.subs.sink = dialogRef.componentInstance.resultEmitter.subscribe((result) => {

      // After dialog is closed 
      console.log('---> Estoy en entrada-dialog.component, se acaba de cerrar el modal action: -->openDialogArticuloNew()<-- con result:', result)
      if (result.data.id) {
        // Añadir al array de artículos el recien creado
        this.articulosList.push(result.data)

        //Añadir al arrayDetalleForm[] el artículo recien creado
        const nuevoDetalleForm = this.fb.group({
          id_articulo: [result.data.id, Validators.required],
          cantidad: ['', [Validators.required, Validators.min(1), CustomVal.esEntero]],
          artFoto: [result.data.foto],
          lote: [result.data.lote],
          artSwLote: [result.data.swLote],
          fecha_caducidad: [null],
        });

        this.arrayDetalleForm.push(nuevoDetalleForm);

        //Hacer un setValue del select del detalleForm para seleccionar el recien creado
        const ultimoFormDetall = this.arrayDetalleForm[this.arrayDetalleForm.length]
        ultimoFormDetall.get('id_articulo')!.setValue(result.data.id);
        this.cambiaArticulo(result.data, this.arrayDetalleForm.length)
      }

    });


  }




  cambiaArticulo(event: any, index: number) {

    console.log('__cambiaArticulo() arrayDetalleForm[]:', this.arrayDetalleForm);
    console.log('__cambiaArticulo() Artículo indice:', index);
    console.log('__cambiaArticulo() Artículo event.stockTot:', event);
    console.log('__cambiaArticulo() arrayDetalleForm[]:', this.arrayDetalleForm);

    const detalleFormGroup = this.arrayDetalleForm[index];
    const idArticuloSeleccionado = event.id;

    let articSel: IArticulo = { 'swLote': false, 'foto': '_noFoto.png' }
    this.articulosSrv.getArticulo(this.arrayDetalleForm[index].get('id_articulo')?.value)
      .subscribe(resp => {
        if (resp.data) articSel = resp.data;
        detalleFormGroup.get('artSwLote')?.setValue(articSel.swLote);
        detalleFormGroup.get('artFoto')?.setValue(articSel.foto);

        detalleFormGroup.get('lote')?.setValue('');
        detalleFormGroup.get('fecha_caducidad')?.setValue(null);

        this.controlObligaLote(index)

      })


    // Verificar si el artículo ya existe en el array
    const articuloExistente = this.arrayDetalleForm.some((detalleForm, i) => {
      if (i !== index) {
        const idArticuloActual = detalleForm.get('id_articulo')?.value;
        if (idArticuloActual === idArticuloSeleccionado) {
          this.notificSrv.aviso('warning', `La línea ${i + 1} ya contiene este artículo`)
          console.log('idArticuloSeleccionado:', event)
        }
        return false;
      }
      return true
    });


    // Cambiar el stockTot del formulario 
    detalleFormGroup.get('stockTot')?.setValue(event.stockTot);

  }


  // customSearchFnArticulo(term: string, item: any) {
  //   // Buscar
  //   term = term.toLocaleLowerCase();
  //   return item.ean.toLocaleLowerCase().indexOf(term) > -1 ||
  //     item.sku.toLocaleLowerCase().indexOf(term) > -1 ||
  //     item.descripcion.toLocaleLowerCase().indexOf(term) > -1;
  // }


  customSearchFnArticulo(term: string, item: any) {
    // Verifica si term es válido antes de convertirlo a minúsculas
    const lowerTerm = term ? term.toLocaleLowerCase() : '';

    // Verifica que item y sus propiedades sean válidas antes de llamar a toLocaleLowerCase
    const ean = item.ean ? item.ean.toLocaleLowerCase() : '';
    const sku = item.sku ? item.sku.toLocaleLowerCase() : '';
    const descripcion = item.descripcion ? item.descripcion.toLocaleLowerCase() : '';

    // Compara el término de búsqueda con las propiedades de item
    return ean.indexOf(lowerTerm) > -1 ||
      sku.indexOf(lowerTerm) > -1 ||
      descripcion.indexOf(lowerTerm) > -1;
  }



  toUpper(fieldName: string) {
    const formControl = this.cabeceraForm.get(fieldName);

    if (formControl && formControl.value) {
      formControl.setValue(formControl.value.toUpperCase());
    }
  }


  //Poner  required lote y fecha  del detalleForm ?
  controlObligaLote(index: number) {
    const detalleForm = this.arrayDetalleForm[index];

    // FormControl  
    const loteControl = detalleForm.get('lote')!;
    const fechaCaducidadControl = detalleForm.get('fecha_caducidad')!;

    // Anula los errores existentes
    loteControl.setErrors(null);
    fechaCaducidadControl.setErrors(null);

    // Aplica o elimina los validadores según el valor de artSwLote
    if (this.fd(index, 'artSwLote')?.value === '1') {

      console.log('  __controlObligaLote() artSwLote:1')

      loteControl.setValidators(Validators.required);
      fechaCaducidadControl.setValidators(Validators.required);
    } else {

      console.log('  __controlObligaLote() artSwLote:0')

      loteControl.clearValidators();
      fechaCaducidadControl.clearValidators();
    }

    // Actualiza el estado del FormControl  
    loteControl.updateValueAndValidity();
    fechaCaducidadControl.updateValueAndValidity();
  }


  // PDF Albaran

  abrirModalAlbaran() {
    this.modalPdfSrv.abrirModal('albaranEntrada', (this.entrada.id || 0).toString(), this.entrada.pdf_albaran || '');
  }

  descargaAlbaranClick(entrada: IApiEntrada) {
    console.log(`__descargaAlbaran() ${entrada}`)
    const urlPdf: string = environment.pdfDir + '/albaranEntrada/' + entrada.pdf_albaran;
    window.open(urlPdf, '_blank');
  }


  borrarPDF(entrada: IApiEntrada) {
    Swal.fire({
      title: 'Desvincular pdf',
      icon: 'question',
      html: `¿Quieres desvincular este pdf como albarán para esta entrada?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.entrada.pdf_albaran = '';
        this.cabeceraForm.patchValue({
          pdf_albaran: ''
        })
        this.notificSrv.aviso('success', `El Pdf se desvinculará de esta entrada cuando la guardes`)
      }
    });

  }


  // Método para determinar si la fecha_caducidad es anterior a hoy
  isExpired(index: number): boolean {
    const hoy = new Date();
    const caducaEl = this.fd(index, 'fecha_caducidad')?.value;

    if (!caducaEl) return false
    return caducaEl < hoy;
  }

  fechaCaducidadChange(index: number) {
    console.log('__blurFechaCaducidad()', index)
    if (this.isExpired(index)) this.notificSrv.aviso('warning', 'Este lote está caducado');

  }






} 